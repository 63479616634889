<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="机构">
              <el-select v-model="form.region" clearable placeholder="请选择服务机构">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="(item, index) in companyList" :key="index" :label="item.companyName" :value="item.companyId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker type="datetime" placeholder="选择开始时间" :picker-options="pickerOptionsStart" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="form.date1" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker type="datetime" placeholder="选择结束时间" :picker-options="pickerOptionsEnd" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="form.date2" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="getAllServeScope(1)">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div class="table-block">
        <el-button plain icon="el-icon-plus" type="primary" @click="add">添加</el-button>
        <el-button plain icon="el-icon-delete" type="danger" @click="handleDelete">删除</el-button>
      </div>
      <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
      <div>
        <el-table
        ref="multipleTable"
        @row-click="handleClickTableRow"
        v-loading="loading"
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center', background: '#F7F9FC' }"
        :cell-style="{ 'text-align': 'center' }"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          label="机构"
          width="180"
          prop="companyName">
        </el-table-column>
        <el-table-column
          prop="regionSelectionName"
          label="已选区域">
        </el-table-column>
        <el-table-column
          prop="createTime"
          width="150"
          label="创建时间">
        </el-table-column>
        <el-table-column
          prop="startTime"
          width="150"
          label="开始时间">
        </el-table-column>
        <el-table-column
          prop="endTime"
          width="150"
          label="结束时间">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="80">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background>
      </el-pagination>
      </div>
    </el-card>
    <addNewArea v-if="showArea" :info="info" @closeAddArea="closeAddArea"></addNewArea>
  </div>
</template>

<script>
import addNewArea from '@/views/appointmentManagement/components/addNewArea'
import { postRequest } from '@/api'
export default {
  data () {
    return {
      form: {
        region: '',
        date1: '',
        date2: ''
      },
      tableData: [],
      multipleSelection: [],
      showArea: false,
      companyList: [],
      total: null,
      pageNo: 1,
      pageSize: 10,
      info: {},
      loading: true,
      disabledSearch: false,
      pickerOptionsStart: {
        disabledDate: time => {
          if (this.form.date2) {
            return time.getTime() > new Date(this.form.date2).getTime()
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          if (this.form.date1) {
            return time.getTime() < new Date(this.form.date1).getTime() - 86400000
          }
        }
      },
    }
  },
  mounted(){
    postRequest('/company/queryCompany',{}).then(res=>{
      console.log(res)
      this.companyList = res
    })
    this.getAllServeScope()
  },
  components: { addNewArea },
  methods:{
    add () {
      console.log(456)
      this.showArea = true,
      this.info = ''
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClick(row) {
      console.log(row);
      this.info = row
      this.showArea = true
    },
    closeAddArea () {
      this.showArea = false
      this.getAllServeScope()
    },
    handleDelete () {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        if (this.multipleSelection.length <= 0) {
          this.$message({
            message: '请选择删除项',
            type: 'warning'
          })
        } else if (this.multipleSelection.length > 0) {
          // this.multipleSelection.map(item => {
          //   let data = {
          //     id: item.id
          //   }
          //   postRequest('/rangeService/deleteRangService', data).then(res=>{
          //     console.log(res)
          //     this.$message({
          //       message: '删除成功',
          //       type: 'success'
          //     })
          //     this.getAllServeScope()
          //   })
          // })
          postRequest('/rangeService/deleteRangService', this.multipleSelection).then(res=>{
              console.log(res)
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.getAllServeScope()
            })
        }
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消删除'
        });
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getAllServeScope()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getAllServeScope()
    },
    getAllServeScope(no){
      this.loading = true
      this.disabledSearch = true
      let data = {
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize,
        startTime: this.form.date1,
        endTime: this.form.date2,
        companyId: this.form.region
      }
      postRequest('/rangeService/queryRangServicePage', data).then(res=>{
        console.log(res)
        this.total = res.count
        this.tableData = res.data
        this.loading = false
        this.disabledSearch = false
      }).catch(()=>{
        this.loading = false
        this.disabledSearch = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.color-white {
  background: rgba(0, 0, 0 ,0);
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
  margin-top: 10px;
}
.table-block {
  margin-bottom: 20px;
}
</style>
