<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="35%"
    :close-on-click-modal="false"
    @close="handleClose">
    <el-card class="main">
      <div class="left">
        <el-form ref="form" :rules="rules" :model="form" label-width="80px">
          <el-form-item label="机构" prop="companyId">
            <el-select v-model="form.companyId" @change="changeJiGou" placeholder="请选择机构">
              <el-option v-for="(item, index) in companyList" :key="index" :label="item.companyName" :value="item.companyId"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="开始时间">
            <el-date-picker type="date" placeholder="选择开始时间" value-format="yyyy-MM-dd" v-model="form.startTime" style="width: 100%;"></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker type="date" placeholder="选择结束时间" value-format="yyyy-MM-dd" v-model="form.endTime" style="width: 100%;"></el-date-picker>
          </el-form-item> -->
          <el-form-item label="起始时间" prop="value1">
            <el-date-picker
              @change="changeDate"
              v-model="form.value1"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
              <el-form-item label="区域选择" prop="regionSelection1">
                <el-select v-model="form.regionSelection1" multiple @change="showareainfo" placeholder="请选择服务区域">
                  <el-option v-for="(item, index) in newAreaList" :key="index" :label="item.name" :value="item.adcode"></el-option>
                </el-select>
              </el-form-item>
        </el-form>
      </div>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit('form')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequest, postRequestAnthor } from '@/api'
export default {
  data () {
    return {
      form: {
        companyId: '',
        startTime: '',
        endTime: '',
        regionSelection: [],
        regionSelectionName: [],
        value1: []
      },
      rules: {
        companyId: [
          {required: true, message: '请选择机构', trigger: 'change'}
        ],
        value1: [
          {required: false, message: '请选择时间', trigger: 'change'}
        ],
        regionSelection1: [
          {required: true, message: '请选择服务区域', trigger: 'input'}
        ]
      },
      dialogVisible: true,
      zoom: 7,
      companyList: [],
      district: [],
      areaList: [],
      newAreaList: [],
      title: '新增服务范围',
      arr: []
    }
  },
  props: ['info'],
  mounted(){
    postRequestAnthor('/company/queryCompany', {}).then(res=>{
      console.log(res, '查询机构列表')
      this.companyList = res
    })
    if (this.info != '') {
      this.title = '修改服务范围'
      postRequest('/rangeService/queryRangServiceById', {id: this.info.id}).then(res=>{
        console.log(res)
        // res.regionSelection1 = res.regionSelection
        if (res.startTime || res.endTime) {
          res.value1 = [res.startTime, res.endTime]
        }
        this.companyList.map(item=>{
          if (item.companyId == res.companyId) {
            this.form.companyName = item.companyName
            this.getAreaList(item.cityInfo)
          }
        })
        res.regionSelection1 = res.regionSelection.split(',')
        res.regionSelectionName = res.regionSelectionName.split(',')
        this.form = res
      })
    }
  },
  methods: {
    handleClose(){
      this.$emit('closeAddArea')
    },
    changeDate(val){
      this.$forceUpdate()
      this.form.startTime = val[0]
      this.form.endTime = val[1]
      console.log(val)
    },
    changeJiGou(val){
      this.form.regionSelection = []
      this.companyList.map(item=>{
        if (item.companyId == val) {
          this.form.companyName = item.companyName
          this.getAreaList(item.cityInfo)
        }
      })
    },
    getAreaList (district) {
      console.log(district)
      var that = this;
      that.district = null
      //加载行政区划插件
      if (!that.district) {
        //实例化DistrictSearch
        var opts = {
          subdistrict: 1, //获取边界不需要返回下级行政区
          extensions: "all", //返回行政区边界坐标组等具体信息
          level: "district", //查询行政级别为 province省级 city市  district区县
        };
        that.district = new AMap.DistrictSearch(opts);
      }
      //行政区查询
      let code = district;
      that.district.search(code, function(status, result) {
        console.log(result)
        let list = []
        list = result.districtList[0].districtList
        console.log(list)
        that.newAreaList = list
      });
    },
    showareainfo(val){
      console.log(val)
      let arr = []
      val.map(item => {
        this.newAreaList.map(i => {
          if (item == i.adcode) {
            arr.push(i.name)
          }
        })
      })
      this.arr = arr
    },
    sureCommit(form){
      this.form.regionSelection= this.form.regionSelection1.join(',')
      this.form.regionSelectionName = this.arr.join(',')
      this.$refs[form].validate((valid) => {
          if (valid) {
            if (this.info != '') {
              postRequest('/rangeService/updateRangService', this.form).then(res=>{
                console.log(res)
                this.$message({
                  message: '修改服务范围成功',
                  type: 'success'
                })
                this.handleClose()
              }).catch(()=>{
              })
            } else {
              postRequest('/rangeService/insertRangService', this.form).then(res=>{
                console.log(res)
                this.$message({
                  message: '添加服务范围成功',
                  type: 'success'
                })
                this.handleClose()
              }).catch(()=>{
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      
    },
  }
}
</script>

<style lang="scss" scoped>
.main {
  .left {
    .el-select {
      width: 100%;
    }
  }
}
.area {
  display: flex;
  justify-content: space-between;
}
.el-date-editor {
  width: 100%;
}
.el-card {
  margin-top: -25px;
}
</style>